.banner {
  position: relative;
  height: 448px;
  object-fit: contain;
  color: white;
}

.banner__content {
  margin-left: 30px;
  padding-top: 140px;
  height: 190px;
}

.banner__title {
  font-size: 3rem;
  font-weight: 800;
  padding-bottom: 0.3rem;
}

.banner__description {
  width: 45rem;
  line-height: 1.3;
  padding-top: 1rem;
  font-size: 0.8rem;
  max-width: 360px;
  height: 80px;
}

.banner--fadeBottom{
    height: 7.4rem;
    background-image: linear-gradient(
        180deg,
        transparent,
        rgba(37,37,37,0.61),
        #111
    );
}

.banner__button{
    cursor: pointer;
    color: white;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    margin-right: 1rem;
    background-color: rgba(51,51,51,0.5);
}

.banner__button:hover{
    color: black;
    background-color: #e6e6e6;
    transition: all 0.2s;
}