.nav {
  position: fixed;
  top: 0;
  padding: 20px;
  width: 100%;
  height: 30px;
  z-index: 1;

  /* Animations */
  transition: all 0.5s;
  transition-timing-function: ease-in;
}
.nav--black {
  background-color: #111;
}

.nav__content {
  display: flex;
  justify-content: space-between;
}

.nav__logo {
  position: fixed;
  top: 25px;
  left: 20px;
  width: 80px;
  object-fit: contain;
  cursor: pointer;
}

.nav__avatar {
  cursor: pointer;
  position: fixed;
  right: 20px;
  width: 30px;
  height: 30px;
}
